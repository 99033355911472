<template lang="pug">
  Draggable(
    class="list-group draggable-selected"
    tag="ul"
    :value="selectedItems"
    animation="200"
    group="draggable"
    ghost-class="ghost"
    handle=".dragger"
    @start="drag = true"
    @end="drag = false"
    @input="handleDrop"
  )
    TransitionGroup(
      :name="drag ? null : 'flip-list'"
    )
      div(
        class="selected"
        v-for="(item, index) in selectedItems"
        :key="item[valueKey]"
      )
        AppCheckbox.highlight-checked(
          :value="true"
          :label="item[titleKey]"
          @change="handleSelect(item, index)"
        )
          FaIcon.dragger(
            icon="grip-lines"
            @click.stop
          )
          template(
            v-if="item[iconKey]"
            v-slot:post-elem
          )
            .checkbox-postfix
              div(:id="vueKey(item) + 'tooltip'")
              AppTooltip(
                :icon="item[iconKey]"
                :type="item[iconType]"
                :title="item[tooltipKey]"
                :container="`#${vueKey(item)}tooltip`"
              )
      div(
        class="not-selected"
        v-for="(item, index) in notSelectedItems"
        :key="item[valueKey]"
      )
        AppCheckbox.highlight-checked(
          :value="false"
          :label="item[titleKey]"
          :disabled="isItemsDisabledByMaxSelectCount"
          @change="handleSelect(item, index)"
        )
          template(
            v-if="item[iconKey]"
            v-slot:post-elem
          )
            .checkbox-postfix
              div(:id="vueKey(item) + 'tooltip'")
              AppTooltip(
                :icon="item[iconKey]"
                :type="item[iconType]"
                :title="item[tooltipKey]"
                :container="`#${vueKey(item)}tooltip`"
              )
</template>

<script>
  import Draggable from "vuedraggable"

  export default {
    components: {
      Draggable,
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    props: {
      selectedItems: {
        type: Array,
        default: () => new Array()
      },
      notSelectedItems: {
        type: Array,
        default: () => new Array()
      },
      valueKey: {
        type: String,
        default: "id"
      },
      titleKey: {
        type: String,
        default: "name"
      },
      vueKey: {
        default() {
          return item => item[this.valueKey]
        }
      },
      iconKey: {
        type: String,
        default: "icon"
      },
      tooltipKey: {
        type: String,
        default: "tooltipText"
      },
      iconType: {
        type: String,
        default: "default"
      },
      maxSelectCount: {
        type: Number
      }
    },

    data() {
      return {
        drag: false
      }
    },

    methods: {
      handleDrop(value) {
        this.$emit("drop", value)
      },

      handleSelect(item, index) {
        this.$emit("select", [item, index])
      }
    },
    computed: {
      isItemsDisabledByMaxSelectCount() {
        if (this.maxSelectCount > 0) {
          return this.selectedItems.length === this.maxSelectCount
        }

        return false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .draggable-selected
    .no-move
      transition: transform 0s

    .ghost
      opacity: 0.5
      background: $default-purple-light

    .dragger
      color: $default-gray-medium
      font-size: 0.75rem
      padding-right: 0.5rem
      height: 24px
      width: 20px
      cursor: ns-resize

      &:active
        color: darken($default-gray-medium, 20%)

    .selected
      height: 28px !important

    .not-selected
      height: 24px !important

    .highlight-checked
      .checkbox-postfix
        opacity: 1
        ::v-deep
          .tooltip-inner
            max-width: initial
</style>
